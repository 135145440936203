@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    color: rgb(223, 223, 223);
    font-family: sans-serif;
    line-height: normal;
}

#root {
    transition: all .4s ease-in-out 0s;
}

p {
    line-height: normal !important;
    font-family: 'Nunito', sans-serif;
}

span {
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    opacity: 0.5;
}

.typed {
    color: rgb(192, 192, 192);
    opacity: 1;
}

.caret {
    transition: left .1s linear 0s;
}

.blink {
    animation: blink 1s infinite;
}

.wrong-red {
    color: rgb(184, 68, 68);
    text-decoration: underline;
    opacity: 1;
}

.wrong-white {
  color: rgb(192, 192, 192);
  text-decoration: underline;
  opacity: 1;
}

.fire {
  animation: Fire 5s linear infinite;
}

.rainbow {
  animation: Rainbow 5s linear infinite;
}

@keyframes blink {
  0%, 100% {
      opacity: 0;
  }
  50%  {
      opacity: 1;
  }
}

@keyframes Rainbow{
  0%{
    color:#A0D468;
  }
  
  20%{
    color:#4FC1E9;
  }
  
  40%{
    color:#FFCE54;
  }
  
  60%{
    color:#FC6E51;
  }
  
  80%{
    color:#ED5565;
  }
  
  100%{
    color:#AC92EC;
  }
}
  

@keyframes Fire{
  0%{
    color: #b31313;
  }
  
  25%{
    color: #ff9000;
  }
  
  50%{
    color: #fdda16;
  }
  
  75%{
    color: #ff9000;
  }
  
  100%{
    color: #b31313;
  }
}